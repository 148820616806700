// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Js_dict from "@rescript/std/lib/es6/js_dict.js";
import * as Pervasives from "@rescript/std/lib/es6/pervasives.js";
import * as Json_decode from "@glennsl/bs-json/src/Json_decode.bs.js";

function query(email, region, subscribed, store, _type) {
  return Js_dict.fromList({
              hd: [
                "query",
                "mutation {\n      postNewsletterSubscription(email: \"" + email + "\", region: \"" + region + "\", subscribed: " + Pervasives.string_of_bool(subscribed) + ", store: \"" + store + "\", type: \"" + _type + "\") {\n        globalGuid\n        email\n        regions {\n          AU {\n            regionId\n          }\n          NZ {\n            regionId\n          }\n          UK {\n            regionId\n          }\n          US {\n            regionId\n          }\n        }\n        lastUpdatedAt\n      }\n    }"
              ],
              tl: /* [] */0
            });
}

function region(json) {
  return {
          regionId: Json_decode.field("regionId", Json_decode.string, json)
        };
}

function regions(json) {
  return {
          au: Json_decode.optional((function (param) {
                  return Json_decode.field("AU", region, param);
                }), json),
          nz: Json_decode.optional((function (param) {
                  return Json_decode.field("NZ", region, param);
                }), json),
          uk: Json_decode.optional((function (param) {
                  return Json_decode.field("UK", region, param);
                }), json),
          us: Json_decode.optional((function (param) {
                  return Json_decode.field("US", region, param);
                }), json)
        };
}

function postNewsletterSubscriptionData(json) {
  return {
          globalGuid: Json_decode.field("globalGuid", Json_decode.string, json),
          email: Json_decode.field("email", Json_decode.string, json),
          regions: Json_decode.field("regions", regions, json),
          lastUpdatedAt: Json_decode.field("lastUpdatedAt", Json_decode.string, json)
        };
}

function postNewsletterSubscription(json) {
  return {
          postNewsletterSubscription: Json_decode.field("postNewsletterSubscription", postNewsletterSubscriptionData, json)
        };
}

function data(json) {
  return {
          data: Json_decode.field("data", postNewsletterSubscription, json)
        };
}

var Decode = {
  region: region,
  regions: regions,
  postNewsletterSubscriptionData: postNewsletterSubscriptionData,
  postNewsletterSubscription: postNewsletterSubscription,
  data: data
};

var NewsletterSubscription = {
  query: query,
  Decode: Decode
};

export {
  NewsletterSubscription ,
}
/* No side effect */
