import { WebConfig } from 'models/webConfig.model';
import handleErrors from 'api/handleErrors';
import { createRequest } from 'api/helpers';

/**
 * Request to fetch server web configuration.
 * @returns Promise with decoded web config response.
 */
const webConfig = async (): Promise<WebConfig> => {
  const response = await createRequest('api/v3/config/client/web/', {
    withMagicUserAgent: true,
  });
  const res = await handleErrors(response);
  const {
    feature_braintree_payment_option_guest,
    feature_braintree_payment_option_member,
    feature_guest_checkout_web,
    feature_stripe_billing_address,
    gift_sender_aliases,
    gift_sender_title,
    gift_sender_subtitle,
    default_pc_purchase_flow,
    feature_classic_switch_for_gif,
    classic_multi_purchase_max_recipient,
    feature_classic_multi_purchase_toggle,
    unwrapping_auth_flow_v2,
    feature_enhanced_wallet_web,
    feature_bff_guest_checkout,
    default_giphy_search_term,
    feature_layer,
    feature_layer_for_classic,
    feature_layer_buying_web,
    feature_bff_fraud,
    feature_email_typo_detection,
  } = res;
  return {
    featureBraintreePaymentOptionGuest: feature_braintree_payment_option_guest,
    featureBraintreePaymentOptionUser: feature_braintree_payment_option_member,
    featureGuestCheckout: feature_guest_checkout_web,
    featureStripeBillingAddress: feature_stripe_billing_address,
    senderAliases: gift_sender_aliases,
    senderTitle: gift_sender_title ?? '',
    senderSubtitle: gift_sender_subtitle ?? '',
    defaultPcPurchaseFlow: default_pc_purchase_flow,
    featureClassicSwitchForGif: feature_classic_switch_for_gif,
    classicMultiPurchaseMaxRecipient: parseInt(classic_multi_purchase_max_recipient) || 5,
    multiPurchaseToggle: feature_classic_multi_purchase_toggle,
    unwrappingAuthFlowV2: unwrapping_auth_flow_v2,
    featureEnhancedWalletWeb: feature_enhanced_wallet_web,
    featureBffGuestCheckout: !!feature_bff_guest_checkout,
    defaultGiphySearchTerm: default_giphy_search_term,
    featureLayer: !!feature_layer,
    featureLayerForClassic: !!feature_layer_for_classic,
    featureLayerBuyingWeb: !!feature_layer_buying_web,
    featureBffFraud: !!feature_bff_fraud,
    featureEmailTypoDetection: !!feature_email_typo_detection,
  };
};

export default webConfig;
