import logger from 'utils/logger';

import Cookies from 'js-cookie';
import { cookieName, decrypt } from 'auth/user/AuthUtils.bs';
import currencies from '../../public/currencies.json';
import { MultiPurchaseToggle } from 'models/webConfig.model';
import { SkuMultiToggleType } from 'models/sku.model';
import { decryptUserCookie } from 'utils/authUtils';
import { getSessionIdToken } from 'api/helpers';

export const dateFormat = (d: string) => {
  const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

  var t = new Date(d);
  return `${t.getDate()} ${monthNames[t.getMonth()]} ${t.getFullYear()}`;
};

export const getCurrency = (currencyCode: string) => {
  const filteredCurrency = currencies.filter(currency => currency.cc === currencyCode);

  if (filteredCurrency.length) return filteredCurrency[0];

  logger.warn(`warn: No currency matching passed currency code: ${currencyCode}`);

  return;
};

export enum COUNTRY {
  Australia = 'Australia',
  GreatBritain = 'GreatBritain',
  UnitedStates = 'UnitedStates',
  NewZealand = 'NewZealand',
}

export enum NATIONALITY {
  AU = 'Australian',
  GB = 'UK',
  US = 'US',
  NZ = 'New Zealand',
}

export enum READABLE_COUNTRY {
  AU = 'Australia',
  GB = 'the UK',
  US = 'the US',
  NZ = 'New Zealand',
}

export enum CURRENCY {
  AUD = 'AUD',
  GBP = 'GBP',
  USD = 'USD',
  NZD = 'NZD',
}

export enum Replacement {
  Zero = '{0}',
}

const countryCode = process.env.NEXT_PUBLIC_APP_COUNTRY_CODE;
const currencyCode = process.env.NEXT_PUBLIC_APP_CURRENCY_CODE;

export const envUtil = {
  countryCode,
  currencyCode,
  getCurrency: (currencyCode: string) => {
    switch (currencyCode) {
      case 'AUD':
        return CURRENCY.AUD;
      case 'GBP':
        return CURRENCY.GBP;
      case 'USD':
        return CURRENCY.USD;
      case 'NZD':
        return CURRENCY.NZD;
      default:
        return 'Invalid currency code.';
    }
  },
  getCurrencyCode: (currency: CURRENCY) => {
    switch (currency) {
      case CURRENCY.AUD:
        return 'AUD';
      case CURRENCY.GBP:
        return 'GBP';
      case CURRENCY.USD:
        return 'USD';
      case CURRENCY.NZD:
        return 'NZD';
    }
  },
  getCountry: () => {
    switch (countryCode) {
      case 'AU':
        return COUNTRY.Australia;
      case 'GB':
        return COUNTRY.GreatBritain;
      case 'US':
        return COUNTRY.UnitedStates;
      case 'NZ':
        return COUNTRY.NewZealand;
      default:
        return 'Invalid country code.';
    }
  },
  getStoreCodename: () => {
    switch (countryCode) {
      case 'AU':
        return 'prezzee_classic_store_au';
      case 'GB':
        return 'prezzee_classic_store_uk';
      case 'US':
        return 'prezzee_classic_store_us';
      case 'NZ':
        return 'prezzee_classic_store_nz';
      default:
        return 'Invalid country code.';
    }
  },
  getNationality: () => {
    return NATIONALITY[countryCode];
  },
  getReadableCountry: () => {
    return READABLE_COUNTRY[countryCode];
  },
};

export const currencyUtil = {
  getDenominationSymbol: (currencyCode: string) => {
    switch (currencyCode) {
      case 'AUD':
        return '$';
      case 'NZD':
        return '$';
      case 'GBP':
        return String.fromCharCode(163);
      case 'USD':
        return '$';
      case 'CAD':
        return '$';
      case 'EUR':
        return '€';
      default:
        return '';
    }
  },
};

export const headerUtil = {
  withAuthHeaderIfAvailable: (headers: any) => {
    const userCookie = Cookies.get(cookieName);
    const decryptedUser = userCookie ? decryptUserCookie(userCookie) : undefined;
    const isMasq = decryptedUser?.masquerade;
    const tokenType = isMasq ? 'Masq' : 'Bearer';

    return decryptedUser?.token
      ? {
          ...headers,
          Authorization: `${tokenType} ${decryptedUser.token}`,
          'x-device-fingerprint': Cookies.get('fingerprint-pz'),
        }
      : headers;
  },
  withContentTypeJson: (headers: any) => {
    return {
      ...headers,
      'Content-Type': 'application/json',
      accept: 'application/json',
    };
  },
  withSecurityToken: (headers: any) => {
    const sessionId = getSessionIdToken();
    if (sessionId) headers = { ...headers, 'X-SECURITY-SESSIONID': sessionId };
    return headers;
  },
};

export const isWebConfigMultipurchase = (webConfigMultiPurchase, user): boolean => {
  switch (webConfigMultiPurchase) {
    case MultiPurchaseToggle.False:
      return false;
    case MultiPurchaseToggle.MemberGuest:
      return true;
    case MultiPurchaseToggle.Member:
      return !!user;
    case MultiPurchaseToggle.Guest:
      return !user && true;
    default:
      return false;
  }
};

export const isMultiPurchaseFF = (skuMultiPurchase, webConfigMultiPurchase, user): boolean => {
  switch (skuMultiPurchase) {
    case SkuMultiToggleType.On:
      return true;
    case SkuMultiToggleType.Off:
      return false;
    case SkuMultiToggleType.Default:
      return isWebConfigMultipurchase(webConfigMultiPurchase, user);
    default:
      return false;
  }
};

export const cookieConfig = {
  acceptCookies: 'acceptCookiesPrezzee',
};

export const getOrderIdFromUrl = (url: string): string | null => {
  const regex = /\/orders\/([^/]+)/;
  const match = url.match(regex);
  return match ? match[1] : null;
};
